import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./services/auth-guard.service";

const routes: Routes = [
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  { path: "login", loadChildren: "./login/login.module#LoginPageModule" },
  {
    path: "register",
    loadChildren: "./register/register.module#RegisterPageModule"
  },
  {
    path: "dashboard",
    loadChildren: "./dashboard/dashboard.module#DashboardPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "resource",
    loadChildren: "./resource/resource.module#ResourcePageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "resource-detail/:id",
    loadChildren:
      "./resource-detail/resource-detail.module#ResourceDetailPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "startup",
    loadChildren: "./startup/startup.module#StartupPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "createdocument",
    loadChildren: "./document/create/create.module#CreatePageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "startbusiness",
    loadChildren: "./business/create/create.module#CreatePageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "chat-lawyer",
    loadChildren: "./chat-lawyer/chat-lawyer.module#ChatLawyerPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "email-lawyer",
    loadChildren: "./email-lawyer/email-lawyer.module#EmailLawyerPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "upload",
    loadChildren: "./document/upload/upload.module#UploadPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "list",
    loadChildren: "./document/list/list.module#ListPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "change-password",
    loadChildren:
      "./change-password/change-password.module#ChangePasswordPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "privacy",
    loadChildren: "./static/privacy/privacy.module#PrivacyPageModule",
    //canActivate: [AuthGuardService]
  },
  {
    path: "terms",
    loadChildren: "./static/terms/terms.module#TermsPageModule",
    //canActivate: [AuthGuardService]
  },
  {
    path: "support",
    loadChildren: "./static/support/support.module#SupportPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "subscription",
    loadChildren: "./subscription/subscription.module#SubscriptionPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "startup-detail/:id",
    loadChildren:
      "./startup-detail/startup-detail.module#StartupDetailPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "forgot-password",
    loadChildren:
      "./forgot-password/forgot-password.module#ForgotPasswordPageModule"
  },
  {
    path: "document-detail/:id",
    loadChildren:
      "./document/document-detail/document-detail.module#DocumentDetailPageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "statesprice/:id",
    loadChildren:
      "./business/statesprice/statesprice.module#StatespricePageModule"
  },
  {
    path: "questionnaire/:id/:price/:category/:state",
    loadChildren:
      "./business/questionnaire/questionnaire.module#QuestionnairePageModule"
  },
  {
    path: "stripe-payment/:productId",
    loadChildren:
      "./subscription/stripe-payment/stripe-payment.module#StripePaymentPageModule"
  },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' }
  // { path: 'resource/detail/:id', loadChildren: './resource/resource.module#ResourcePageModule' }
];

@NgModule( {
  imports: [
    RouterModule.forRoot( routes, { preloadingStrategy: PreloadAllModules } )
  ],
  exports: [RouterModule]
} )
export class AppRoutingModule { }
