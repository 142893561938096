// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firstorepath: 'dev/LegalStart',
  firebase: {
    apiKey: "AIzaSyCLXAhbTba3Z93-5B4GH1hN7UCr0MKINGA",
    authDomain: "legalstart2019-c8f24.firebaseapp.com",
    databaseURL: "https://legalstart2019-c8f24.firebaseio.com",
    projectId: "legalstart2019-c8f24",
    storageBucket: "gs://legalstart2019-c8f24.appspot.com",
    messagingSenderId: "444611513309",
    appId: "1:444611513309:web:2575225549540f25a78434",
    measurementId: "G-Y0BPFBEEFL"

    // apiKey: "AIzaSyBDeiGfA4n41OuAE_56HR4fZfPNadtTrKE",
    // authDomain: "legalstart-5ea59.firebaseapp.com",
    // databaseURL: "https://legalstart-5ea59.firebaseio.com",
    // projectId: "legalstart-5ea59",
    // storageBucket: "gs://legalstart-5ea59.appspot.com",
    // messagingSenderId: "902256018483",
    // appId: "1:902256018483:web:85a908f9168d807e7dcf46"
  },
  devurl: 'https://dev-legalstart.pantheonsite.io/'
};


// export const environment = {
//   production: false,


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
