import { Injectable } from '@angular/core';

import { QuestionBase } from '../model/Question/question-base';

import { Textbox } from '../document/document-detail/textbox';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, from } from 'rxjs';
import { map } from 'rxjs/operators';

let headers = new HttpHeaders( {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
    'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization'
} );
@Injectable()
export class QuestionService {

    items;
    url = 'https://dev-legalstart.pantheonsite.io';
    constructor ( private http: HttpClient ) { }

    // TODO: get from a remote source of question metadata
    // TODO: make asynchronous
    getQuestions ( nid ): Observable<any[]> {
        var subject = new Subject<any>();
        let questions: QuestionBase<any>[] = [

            //   new DropdownQuestion({
            //     key: 'brave',
            //     label: 'Bravery Rating',
            //     options: [
            //       {key: 'solid',  value: 'Solid'},
            //       {key: 'great',  value: 'Great'},
            //       {key: 'good',   value: 'Good'},
            //       {key: 'unproven', value: 'Unproven'}
            //     ],
            //     order: 3
            //   }),



            //   new TextboxQuestion({
            //     key: 'emailAddress',
            //     label: 'Email',
            //     type: 'emaiul',
            //     order: 2
            //   })
        ];




        this.http.get( 'https://dev-legalstart.pantheonsite.io/doc-question/' + nid ).subscribe( res => {
            Object.keys( res ).forEach( key => {
                res[key]['expanded'] = false;
            } );
            this.items = JSON.parse( JSON.stringify( res ) );
            this.items.forEach( element => {
                element.body = element.body.replace( /<p>|<\/p>|<br \/>/g, "" );
                if ( element.title == "ADDITIONAL COMMENTS" ) { //for additional comment
                    questions.push( new Textbox( {
                        key: element.nid,
                        label: element.title,
                        value: '',
                        required: false,
                        order: 1,
                        expanded: false,
                        body: element.body
                    } ) )
                } else {
                    questions.push( new Textbox( {
                        key: element.nid,
                        label: element.title,
                        value: '',
                        required: true,
                        order: 1,
                        expanded: false,
                        body: element.body
                    } ) )
                }

            } );

            subject.next( questions.sort( ( a, b ) => a.order - b.order ) );
        } );
        return subject.asObservable();
    }

    createDoc ( data ) {
        return this.http.post( this.url + '/create_document.json', data, { headers } );
    }

}
