import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, tap, switchMap } from 'rxjs/operators';

import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';


import { User } from '../model/User/user';
import { AngularFirestore } from '@angular/fire/firestore';

import { AuthenticationService } from './authentication.service';
import { ThrowStmt } from '@angular/compiler';

@Injectable( {
  providedIn: 'root'
} )
export class AuthGuardService implements CanActivate {
  user: Observable<User | null>;
  uid;
  constructor (
    private auth: AuthenticationService,
    private router: Router,
  ) { }
  // canActivate ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {
  //   this.auth.user.subscribe( user => {
  //     this.uid = user.uid;
  //     console.log( user.uid );
  //     if ( !this.uid || this.uid == null ) {
  //       console.log( this.auth.user );

  //       this.router.navigate( ['login'] );
  //     }
  //     else {
  //       console.log( 'here' );

  //       return true;
  //     }
  //     console.log( 'out' );

  //   } );

  //   return true;
  // }

  canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot ): Observable<boolean> {

    return this.auth.user.pipe(
      take( 1 ),
      map( user => !!user ),
      tap( loggedIn => {
        if ( !loggedIn ) {
          console.log( 'access denied' )
          this.router.navigate( ['/login'] );
        }
      } )
    )

  }
}