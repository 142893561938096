import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { firebase } from "@firebase/app";
import { auth } from "firebase";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";

import { Observable, of, BehaviorSubject } from "rxjs";
import { switchMap, startWith, tap, filter } from "rxjs/operators";
import { User } from "./../model/User/user";

@Injectable()
export class AuthenticationService {
  user: Observable<User | null>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs
            .doc<User>(`dev/LegalStart/users/${user.uid}`)
            .valueChanges();
        } else {
          return of(null);
        }
      })
      // tap(user => localStorage.setItem('user', JSON.stringify(user))),
      // startWith(JSON.parse(localStorage.getItem('user')))
    );
  }

  ////// OAuth Methods /////

  googleLogin() {
    const provider = new auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  githubLogin() {
    const provider = new auth.GithubAuthProvider();
    return this.oAuthLogin(provider);
  }

  facebookLogin() {
    const provider = new auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  twitterLogin() {
    const provider = new auth.TwitterAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider: any) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then((credential) => {
        //this.notify.update('Welcome to !!!', 'success');
        return credential;
      })
      .catch((error) => this.handleError(error));
  }

  //// Anonymous Auth ////

  anonymousLogin() {
    return this.afAuth.auth
      .signInAnonymously()
      .then((credential) => {
        //this.notify.update('Welcome to !!!', 'success');
        return credential; // if using firestore
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  //// Email/Password Auth ////

  emailSignUp(email: string, password: string, useraf: any, platForm:any) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((credential) => {
        localStorage.setItem("intro", "1");
        return this.updateUserData(credential.user, useraf,platForm); // if using firestore
      });
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then((credential) => {
        //this.router.navigate[( 'dashboard' )];
        //this.notify.update('Welcome back!', 'success');
        return credential;
      });
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const fbAuth = auth();

    return (
      fbAuth
        .sendPasswordResetEmail(email)
        // .then(() => //this.notify.update('Password update email sent', 'info'))
        .catch((error) => this.handleError(error))
    );
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(["login"]);
      // this.remoteconfig.token$ = new BehaviorSubject(undefined);
    });
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    //this.toastr.error(error.message);
    //this.notify.update(error.message, 'error');
  }

  // Sets user data to firestore after succesful login
  private updateUserData(user: User, useraf: any,platForm:any) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `dev/LegalStart/users/${user.uid}`
    );

    const data: User = {
      uid: user.uid,
      email: useraf.email || null,
      displayName: useraf.first_name + " " + useraf.last_name || "user",
      photoURL: user.photoURL || "https://goo.gl/Fz9nrQ",
      role: "user",
      docUpdated: new Date().getTime(),
      chatUpdated: new Date().getTime(),
      firstName: useraf.first_name,
      lastName: useraf.last_name,
      type:platForm
    };
    return userRef.set(data);
  }
}
