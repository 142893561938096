import { QuestionBase } from '../../model/Question/question-base';

export class RadioButton extends QuestionBase<string> {
    controlType = 'radio';
    options: { key: string, value: string }[] = [];

    constructor ( options: {} = {} ) {
        super( options );
        this.options = options['options'] || [];
    }
}