import { QuestionBase } from '../../model/Question/question-base';

export class Textbox extends QuestionBase<string> {
  controlType = 'textbox';
  type: string;

  constructor ( options: {} = {} ) {
    super( options );
    this.type = options['type'] || '';
  }
}
