import { QuestionBase } from '../../model/Question/question-base';

export class Checkbox extends QuestionBase<string> {
    controlType = 'checkbox';
    type: string;

    constructor ( options: {} = {} ) {
        super( options );
        this.type = options['type'] || '';
    }
}
