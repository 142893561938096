import { Component } from "@angular/core";

import { Platform, NavController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { map, tap } from "rxjs/operators";
import { AuthenticationService } from "./services/authentication.service";
import { Router } from "@angular/router";
//import { GoogleAnalytics } from "@ionic-native/google-analytics/ngx";

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from "@capacitor/core";

const { PushNotifications } = Plugins;

@Component( {
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
} )
export class AppComponent {
  constructor (
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navController: NavController,
    private router: Router,
    //private ga: GoogleAnalytics,
    private authService: AuthenticationService
  ) {
    this.initializeApp();
  }

  ngOnInit () {
    // this.ga
    //   .trackView( "List Page" )
    //   .then( () => { } )
    //   .catch( ( e ) => console.log( e ) );
  }

  initializeApp () {
    this.platform.ready().then( () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      PushNotifications.register();
      // On success, we should be able to receive notifications
      PushNotifications.addListener(
        "registration",
        ( token: PushNotificationToken ) => {
          console.log( token );
          localStorage.setItem( "deviceToken", token.value );
          console.log( "Push registration success, token: " + token.value );
          console.log( localStorage.getItem( "deviceToken" ) );
        }
      );

      // if ( this.platform.is( 'android' ) || this.platform.is( 'ios' ) ) {
      //   this.ga
      //     .startTrackerWithId( "UA-148493391-1" )
      //     .then( () => { } )
      //     .catch( ( e ) => alert( "Error starting GoogleAnalytics == " + e ) );
      // }

      // Some issue with our setup and push will not work
      PushNotifications.addListener( "registrationError", ( error: any ) => {
        console.log( "Error on registration: " + JSON.stringify( error ) );
      } );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        ( notification: PushNotification ) => {
          console.log( "Push received: " + JSON.stringify( notification ) );
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        ( notification: PushNotificationActionPerformed ) => {
          console.log( "Push action performed: " + JSON.stringify( notification ) );
          if (
            notification["notification"]["data"]["notification_type"] == "chat"
          ) {
            this.router.navigate( ["/chat-lawyer"] );
          }
        }
      );
    } );
  }

  mailto () {
    window.open( `mailto:info@legalstartapp.com`, "_system" );
  }

  logout () {
    this.authService.signOut();
    localStorage.clear();
  }
}
