export class QuestionBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  expanded: boolean;
  parentId: string;
  body: string;
  constructor ( options: {
    value?: T,
    key?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: string,
    expanded?: boolean,
    parentId?: string,
    body?: string,
  } = {} ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.expanded = !!options.expanded;
    this.parentId = options.parentId || '';
    this.body = options.body || '';
  }
}
