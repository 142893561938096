import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
// import { environment } from 'src/environments/environment';
import { AuthGuardService } from "./services/auth-guard.service";
import { AuthenticationService } from "./services/authentication.service";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { IonicStorageModule } from "@ionic/storage";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";

import { HttpClientModule } from "@angular/common/http";
import { FileSizePipe } from "./file-size.pipe";
import { File } from "@ionic-native/file/ngx";
import { FileTransfer } from "@ionic-native/file-transfer/ngx";

import { QuestionService } from "./services/questions.service";
import { BusinessquestionService } from "./services/businessquestions.service";

import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { ExpandableComponent } from "./components/expandable/expandable.component";

//import { GoogleAnalytics } from "@ionic-native/google-analytics/ngx";

import { from } from "rxjs";

@NgModule( {
  declarations: [AppComponent, FileSizePipe, ExpandableComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp( environment.firebase ),
    ReactiveFormsModule,
    AngularFirestoreModule,
    HttpClientModule,
    AngularFireStorageModule,
    // ServiceWorkerModule.register( 'ngsw-worker.js', { enabled: environment.production } )
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthGuardService,
    //GoogleAnalytics,
    AuthenticationService,
    QuestionService,
    BusinessquestionService,
    File,
    FileTransfer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
} )
export class AppModule { }
