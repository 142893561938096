import { Injectable } from '@angular/core';

import { QuestionBase } from '../model/Question/question-base';

import { Textbox } from '../business/questionnaire/textbox';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { RadioButton } from '../business/questionnaire/radiobutton';
import { Checkbox } from '../business/questionnaire/checkbox';

let headers = new HttpHeaders( {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
    'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization'
} );
@Injectable()
export class BusinessquestionService {

    items;
    url = 'https://dev-legalstart.pantheonsite.io';
    constructor ( private http: HttpClient ) { }

    // TODO: get from a remote source of question metadata
    // TODO: make asynchronous
    getQuestions ( cid ): Observable<any[]> {
        var subject = new Subject<any>();
        let questions: QuestionBase<any>[] = [

            //   new DropdownQuestion({
            //     key: 'brave',
            //     label: 'Bravery Rating',
            //     options: [
            //       {key: 'solid',  value: 'Solid'},
            //       {key: 'great',  value: 'Great'},
            //       {key: 'good',   value: 'Good'},
            //       {key: 'unproven', value: 'Unproven'}
            //     ],
            //     order: 3
            //   }),



            //   new TextboxQuestion({
            //     key: 'emailAddress',
            //     label: 'Email',
            //     type: 'emaiul',
            //     order: 2
            //   })
        ];


        if ( cid == 1 ) {
            this.http.get( 'https://dev-legalstart.pantheonsite.io/llc-questions' ).subscribe( res => {
                Object.keys( res ).forEach( key => {
                    res[key]['expanded'] = false;
                } );
                this.items = JSON.parse( JSON.stringify( res ) );

                console.log( this.items );

                this.items.forEach( element => {
                    element.body = element.body.replace( /<p>|<\/p>|<br \/>/g, "" );
                    //element.body = element.body.replace( /(<br>|<\/br>|<br \/>)/mgi, "\n" );

                    // console.log( element.body );

                    if ( element.field_question_type === 'text' ) {
                        if ( element.title == "ADDITIONAL COMMENTS" ) {
                            questions.push( new Textbox( {
                                key: element.nid,
                                label: element.title,
                                value: '',
                                required: false,
                                order: 1,
                                expanded: false,
                                parentId: element.field_parent_id,
                                body: element.body
                            } ) )
                        } else {
                            questions.push( new Textbox( {
                                key: element.nid,
                                label: element.title,
                                value: '',
                                required: true,
                                order: 1,
                                expanded: false,
                                parentId: element.field_parent_id,
                                body: element.body
                            } ) )
                        }

                    }
                    else if ( element.field_question_type === 'radio' ) {
                        questions.push( new RadioButton( {
                            key: element.nid,
                            label: element.title,
                            required: false,
                            parentId: element.field_parent_id,
                            body: element.body,
                            options: [
                                { key: 'Yes', value: 'Yes' },
                                { key: 'No', value: 'No' }
                            ],
                            order: 3 //if want later then put 3
                        } ) )
                    }
                    else if ( element.field_question_type === 'checkbox' ) {
                        questions.push( new Checkbox( {
                            key: element.nid,
                            label: element.title,
                            value: element.nid,
                            required: false,
                            order: 1,
                            expanded: false,
                            parentId: element.field_parent_id,
                            body: element.body
                        } ) )
                    }
                } );

                subject.next( questions.sort( ( a, b ) => a.order - b.order ) );
            } );
        }

        else {


            this.http.get( 'https://dev-legalstart.pantheonsite.io/corporate-questions' ).subscribe( res => {
                Object.keys( res ).forEach( key => {
                    res[key]['expanded'] = false;
                } );
                this.items = JSON.parse( JSON.stringify( res ) );
                console.log( this.items );
                this.items.forEach( element => {
                    element.body = element.body.replace( /<p>|<\/p>|<br \/>/g, "" );
                    //element.body = element.body.replace( /(<br>|<\/br>|<br \/>)/mgi, "" );
                    if ( element.field_corp_question_type === 'text' ) {
                        if ( element.title == "ADDITIONAL COMMENTS" ) {
                            questions.push( new Textbox( {
                                key: element.nid,
                                label: element.title,
                                value: '',
                                required: false,
                                //order: 1,
                                expanded: false,
                                parentId: element.field_corp_parent_id,
                                body: element.body
                            } ) )
                        } else {
                            questions.push( new Textbox( {
                                key: element.nid,
                                label: element.title,
                                value: '',
                                required: true,
                                //order: 1,
                                expanded: false,
                                parentId: element.field_corp_parent_id,
                                body: element.body
                            } ) )
                        }

                    }
                    else if ( element.field_corp_question_type === 'radio' ) {
                        questions.push( new RadioButton( {
                            key: element.nid,
                            label: element.title,
                            required: false,
                            parentId: element.field_corp_parent_id,
                            body: element.body,
                            options: [
                                { key: 'Yes', value: 'Yes' },
                                { key: 'No', value: 'No' }
                            ],
                            //order: 3
                        } ) )
                    }
                    else if ( element.field_corp_question_type === 'checkbox' ) {
                        questions.push( new Checkbox( {
                            key: element.nid,
                            label: element.title,
                            value: element.nid,
                            required: false,
                            //order: 1,
                            expanded: false,
                            parentId: element.field_corp_parent_id,
                            body: element.body
                        } ) )
                    }
                } );

                subject.next( questions.sort( ( a, b ) => a.order - b.order ) );
            } );
        }
        return subject.asObservable();
    }

    createDoc ( data ) {
        return this.http.post( this.url + '/create_business_document.json', data, { headers } );
    }

}
